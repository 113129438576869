/* global Typekit */
// Only code specific to this site should be here. Anything that can be used across multiple sites
// should be pushed into the KMS.
$().ready(function() {
	if ($("body").height() < $(window).height()) {
		$("footer").css({height:($("footer").height()+($(window).height()-$("body").height()))+"px"});
	}
	adjustNewsFeedHeight();
	newsScroll("#news");
	bannerParralax();
	mobileMenu();
	galleryScroll();
});
export function adjustNewsFeedHeight(){
	var maxHeight = 0;
	$("#news article").each(function() {
		if ($(this).height()>maxHeight) maxHeight = $(this).height();
	});
	$("#news article").each(function() {
		$(this).css({height:maxHeight+"px"});
	});
}
let news = null;
async function getNews() {
	if (news) {
		return Promise.resolve(news);
	}
	try {
		const response = await fetch("/content/news/",
			{
				method: "GET", 
				headers: {
					"accept": "application/json"
				}
			}
		);
		const data = await response.json();
		news = data;
		return news;
	} catch (error) {
		// Handle errors (e.g., network errors)
		return Promise.reject(error);
	}
}
async function getNewsItem(offset){
	let news = await getNews();
	news = news.content_items;
	return news[offset % news.length];
}
export function createNewsItem(){
	return $("<article class=\"item\"><header><h1></h1></header><section></section><footer><a href=\"\">Read Article</a><a class=\"right\" href=\"/news/\">View All</a><span class=\"loader\"></footer></article>");
}
export function populateNewsItem(item, data) {
	item.find("h1").html(data.title);
	item.find("section").html(data.teaser);
	item.find("a[href=\"\"]").attr("href",data.url);
	adjustNewsFeedHeight();
	item.find(".loader").remove();
}
var scrollSpeed = 500;
export function newsScroll() {
	//TODO: Add news scrolling
	var newsindex = 0;
	$("#news .prev").click(function() {
		if ($("#news article").length==4) return;
		newsindex--;
		var newitem = createNewsItem();
		var olditem = $("#news article:last");
		newitem.css({opacity:0});
		$("#news .contentwrap").prepend(" ");
		$("#news .contentwrap").prepend(newitem);
		getNewsItem(newsindex).then(data => {
			populateNewsItem(newitem, data);
		});
		newitem.animate({opacity:1},scrollSpeed, function(){
			adjustNewsFeedHeight();
		});
		olditem.animate({opacity:"0"},scrollSpeed, function(){
			$(this).remove();
		});
	}).show();
	$("#news .next").click(function() {
		if ($("#news article").length==4) return;
		newsindex++;
		var newitem = createNewsItem();
		var olditem = $("#news article:first");
		newitem.css({opacity:0});
		$("#news .contentwrap").append(" ");
		$("#news .contentwrap").append(newitem);
		getNewsItem(newsindex+3).then(data => {
			populateNewsItem(newitem, data);			
		});
		newitem.animate({opacity:1},scrollSpeed, function(){
			adjustNewsFeedHeight();
		});
		olditem.animate({opacity:"0"},scrollSpeed, function(){
			$(this).remove();
		});
	}).show();
}

export function bannerParralax() {
	var bannerWidth,bannerHeight,bannerTop,bannerLeft,
		$banner=$("#banner");
	if ($banner.length >0){
		var bannerResize = function(){
			bannerWidth = $banner.width();
			bannerHeight = $banner.height();
			bannerTop = $banner.offset().top;
			bannerLeft = $banner.offset().left;
		};
		bannerResize();
		$(window).resize(bannerResize);
		$("#banner").mousemove(function(e) {
			if ($(window).width()>1024){
				var x = parseFloat(e.clientX-bannerLeft)/bannerWidth;
				var y = parseFloat(e.clientY-bannerTop)/bannerHeight;
				$("#banner .parralax").css({marginLeft:(110-x*20)+"px",bottom:(-135+y*20)+"px"});
				$("#banner").css({backgroundPosition: (50-(x*2))+ "% " + (50-(y*2)) +"%"});
			}
		});
	}
}
export function handleMediaQueryChange(event) {
	if (event.matches) {
		// The viewport is 768px or less
	} else {
		// The viewport is more than 768px
		$("header.top nav li").each(function() {
			$(this).removeClass("active");
		});
	}
}
export function mobileMenu(){
	const mediaQuery = window.matchMedia("(max-width: 768px)");
	handleMediaQueryChange(mediaQuery);
	mediaQuery.addEventListener("change", handleMediaQueryChange);


	$("header.top nav li").each(function() {
		if ($(this).find("li").length > 0) {
			$(this).children("a").append("<span class=\"expando\"></span>");
		}
	});
	$("header.top nav li .expando").click(function(e) {
		e.stopPropagation();
		e.preventDefault();
		var $li = $(this).closest("li");
		$li.toggleClass("active");
	});

	$("header.top nav .menu-toggle").click(function() {
		$("header.top nav").toggleClass("active");
	});


}
function galleryScroll() {
	var scroller = $(".bottom-gallery-scroll");
	$(".bottom-gallery .prev").click(function() {
		scroller.animate({scrollLeft:(scroller.scrollLeft()-300)});
	}).show();
	$(".bottom-gallery .next").click(function() {
		scroller.animate({scrollLeft:(scroller.scrollLeft()+300)});
	}).show();
}
/************ Typekit **************/
try{Typekit.load();}catch(e){
	// I want application to not crash, but don't care about the message
}